<template>
  <div>
    <!-- 活动周标题 -->
    <top :islist="islist"></top>
    <!-- 模块标题 -->
    <tech-title
      :category1="category1"
      :category2="category2"
      :subtitle="subtitle"
      :iscloud="iscloud"
    ></tech-title>
    <!-- 列表 -->
    <ul class="list">
      <li v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <img :src="item.pictureVideo" alt="" />
        <div class="title">
          <p>• {{ item.theme }}</p>
          <div class="info">
            <span>作者：{{ item.author }}</span
            ><span>单位：{{ item.unit }}</span>
          </div>
        </div>
      </li>
    </ul>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <page-footer />
  </div>
</template>

<script>
import Top from '@/views/mobile/components/Top.vue';
import TechTitle from './components/TechTitle.vue';
import PageFooter from './components/PageFooter.vue';
import Pagination from './components/Pagination.vue';
import { getCloudList } from '@/api/mobile.js';
export default {
  name: 'MLife',
  components: {
    Top,
    TechTitle,
    PageFooter,
    Pagination,
  },
  data() {
    return {
      category1: '云科普',
      category2: '展厅',
      subtitle: '科技改变生活',
      islist: true,
      iscloud: true,
      total: 0,
      list: [],
      listQuery: {
        page: 1,
        limit: 5,
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$nextTick(() => {
      //跳转返回 IOS不兼容页面自动刷新问题
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        window.onpageshow = function (event) {
          if (event.persisted) {
            window.location.reload();
          }
        };
      }
    });
  },
  methods: {
    getList() {
      let params = {
        pageNo: this.listQuery.page,
        pageSize: this.listQuery.limit,
        flag: 3,
      };
      getCloudList(params).then((res) => {
        this.total = res.data.total;
        this.list = res.data.list;
      });
    },
    goDetail(id) {
      this.$router.push('/kjsh/' + id);
    },
  },
};
</script>

<style scoped lang="scss">
.list {
  padding: 30px;
}
.list li {
  width: 690px;
  height: 480px;
  position: relative;
  margin-top: 30px;
}
.list li img {
  width: 690px;
  height: 480px;
  object-fit: cover;
}
.list .title {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  padding: 10px 20px 20px 20px;
  background: linear-gradient(90deg, #4450e2, #48b9fd);
}
.list .title p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  font-size: 30px;
  line-height: 60px;
}
.list .title .info {
  display: flex;
  font-size: 24px;
  color: #fff;
}
.list .title .info span {
  flex: 1;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
</style>
